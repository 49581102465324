import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { db } from "../redux/firebase";
import { collection as firebaseCollection, addDoc } from "firebase/firestore";

function AddNew() {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [lang, setLang] = useState("English");
  const [collection, setCollection] = useState("faqEng");

  const changeToEng = () => {
    if (lang !== "English") {
      setLang("English");
      setCollection("faqEng");
    }
  };

  const changeToDeu = () => {
    if (lang !== "German") {
      setLang("German");
      setCollection("faqDeu");
    }
  };

  const changeToJpn = () => {
    if (lang !== "Japanese") {
      setLang("Japanese");
      setCollection("faqJpn");
    }
  };

  const confirmAdd = () => {
    if (text1 !== "" && text2 !== "") {
      confirmAlert({
        message: "Are you sure?",
        buttons: [
          {
            label: "Add question",
            onClick: handleAddQuestion,
          },
          {
            label: "Cancel",
          },
        ],
      });
    } else alert("Missing question or answer");
  };

  const handleAddQuestion = async () => {
    const docRef = await addDoc(firebaseCollection(db, collection), {
      question: text1,
      answer: text2,
    });
    alert("Question added with id ", docRef.id);
    setText2("");
    setText1("");
  };

  return (
    <div className="adminAddNewQuestion">
      <div className="adminButtons">
        <p className="adminLang" onClick={changeToEng}>
          ENG
        </p>
        <p className="adminLang" onClick={changeToDeu}>
          DEU
        </p>
        <p className="adminLang" onClick={changeToJpn}>
          JPN
        </p>
      </div>
      <div className="adminInput">
        <p>{`New ${lang} question`}</p>
        <textarea
          value={text1}
          onChange={(e) => setText1(e.target.value)}
        ></textarea>
      </div>

      <div className="adminInput">
        <p>{`New ${lang} answer`}</p>
        <textarea
          value={text2}
          onChange={(e) => setText2(e.target.value)}
        ></textarea>
      </div>
      <button className="adminButton" onClick={confirmAdd}>
        Add Question
      </button>
    </div>
  );
}

export default AddNew;
