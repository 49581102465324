import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./languageSlice";
import userReducer from "./userSlice";

export default configureStore({
  reducer: {
    language: languageReducer,
    user: userReducer,
  },
});
