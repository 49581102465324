import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCxrmE7rRgFcd-SPGUfg2rCH8AMzCNHdgg",
  authDomain: "itadakihealthy.firebaseapp.com",
  projectId: "itadakihealthy",
  storageBucket: "itadakihealthy.appspot.com",
  messagingSenderId: "307624845849",
  appId: "1:307624845849:web:1979b01d7c5f501058c088",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };
