import React, { useState, useEffect } from "react";
import Juicer from "../homeComponents/Juicer";
import Carousel from "react-material-ui-carousel";
import "./Home.css";
import { useSelector } from "react-redux";
import SignUpButton from "../homeComponents/SignUpButton";
import Collage from "../homeComponents/Collage";
import { selectLanguage } from "../redux/languageSlice";
import Board from "../homeComponents/Board";
import Item from "../homeComponents/Item";
import img1 from "../images/fruits-cut.jpeg";
import img11440 from "../images/fruits-cut1440.jpeg";
import img11000 from "../images/fruits-cut1000.jpeg";
import img1600 from "../images/fruits-cut600.jpeg";
import img2 from "../images/healthy-breakfast.jpeg";
import img21440 from "../images/healthy-breakfast1440.jpeg";
import img21000 from "../images/healthy-breakfast1000.jpeg";
import img2600 from "../images/healthy-breakfast600.jpeg";
import img3 from "../images/sillyGirls.jpeg";
import img31440 from "../images/sillyGirls1440.jpeg";
import img31000 from "../images/sillyGirls1000.jpeg";
import img3600 from "../images/sillyGirls600.jpeg";
import img4 from "../images/familyCooking.jpeg";
import img41440 from "../images/familyCooking1440.jpeg";
import img41000 from "../images/familyCooking1000.jpeg";
//import img4600 from "../images/familyCooking600.jpeg";
//import img5 from "../images/pregnant.jpeg";
import img5 from "../images/pregnant1440a.jpeg";
import img51440 from "../images/pregnant1440a.jpeg";
//import img51000 from "../images/pregnant1000.jpeg";
//import img5600 from "../images/pregnant600.jpeg";
import Stamps from "../homeComponents/Stamps";

function Home(props) {
  const language = useSelector(selectLanguage);

  //translations
  const [subtitle, setSubtitle] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setSubtitle("Vegan Nutrition Coaching");
      } else if (language === "deu") {
        setSubtitle("Vegane Ernährungsberatung");
      } else {
        setSubtitle("ビーガン栄養コーチング");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  const items = [
    {
      image: img2,
      image1440: img21440,
      image1000: img21000,
      image600: img2600,
      title: "itadakihealthy",
      subtitle: subtitle,
    },
    {
      image: img3,
      image1440: img31440,
      image1000: img31000,
      image600: img3600,
      title: "itadakihealthy",
      subtitle: subtitle,
    },
    {
      image: img1,
      image1440: img11440,
      image1000: img11000,
      image600: img1600,
      title: "itadakihealthy",
      subtitle: subtitle,
    },
    {
      image: img4,
      image1440: img41440,
      image1000: img41000,
      image600: img41000,
      title: "itadakihealthy",
      subtitle: subtitle,
    },
    {
      image: img5,
      image1440: img51440,
      // image1000: img51000,
      // image600: img5600,
      image1000: img51440,
      image600: img51440,
      title: "itadakihealthy",
      subtitle: subtitle,
    },
  ];
  return (
    <div className="home">
      <div className="container">
        <div className="home__top">
          <Carousel autoPlay={true} className="home__carousel">
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </div>
        <Board />
        <SignUpButton buttonTextClass="signUpButton__text" />
        <Juicer />
        <Collage />
        <Stamps stampsClass="stampsBig" />
      </div>
    </div>
  );
}

export default Home;
