import React, { useState, useEffect, useLayoutEffect } from "react";
import "./About.css";
//import aboutImg from "../images/vegetables.jpg";
import aboutImg1440 from "../images/vegetables1440.jpeg";
import aboutImg1000 from "../images/vegetables1000.jpeg";
import aboutImg600 from "../images/vegetables600.jpeg";
import holdingApple from "../images/holding-apple.jpeg";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import SignUpButton from "../homeComponents/SignUpButton";
import Stamps from "../homeComponents/Stamps";
import holdinAppleSm from "../images/holding-apple-cut.jpeg";
import { db } from "../redux/firebase";
import { doc, getDoc } from "firebase/firestore";
import createMarkup from "../utils/functions";

function About() {
  const language = useSelector(selectLanguage);

  const [bioEngTop, setBioEngTop] = useState();
  const [bioEngBottom, setBioEngBottom] = useState();
  const [bioDeuTop, setBioDeuTop] = useState();
  const [bioDeuBottom, setBioDeuBottom] = useState();
  const [bioJpnTop, setBioJpnTop] = useState();
  const [bioJpnBottom, setBioJpnBottom] = useState();

  //translations
  const [title, setTitle] = useState();
  const [subtitle, setSubtitle] = useState();
  const [infoTop, setInfoTop] = useState();
  const [infoBottom, setInfoBottom] = useState();

  useLayoutEffect(() => {
    const getData = async () => {
      const docRef = doc(db, "bio", "P9wwjlot7yDNy63STyx9");

      const docSnap = await getDoc(docRef);

      if (docSnap.exists) {
        setBioEngBottom(docSnap.data().bioEngBottom);
        setBioEngTop(docSnap.data().bioEngTop);
        setBioDeuTop(docSnap.data().bioDeuTop);
        setBioDeuBottom(docSnap.data().bioDeuBottom);
        setBioJpnTop(docSnap.data().bioJpnTop);
        setBioJpnBottom(docSnap.data().bioJpnBottom);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    getData();
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng" && bioEngBottom !== null && bioEngTop !== null) {
        setTitle("About Leonore");
        setSubtitle("Certified Vegan Nutritionist");
        setInfoTop(
          <div className="" dangerouslySetInnerHTML={createMarkup(bioEngTop)} />
        );
        setInfoBottom(
          <div
            className="about__infoBottom"
            dangerouslySetInnerHTML={createMarkup(bioEngBottom)}
          />
        );
      } else if (language === "deu") {
        setTitle("Wer ist itadakihealthy?");
        setSubtitle("Leonore, zertifizierte Ernährungsberaterin");
        setInfoTop(
          <div className="" dangerouslySetInnerHTML={createMarkup(bioDeuTop)} />
        );
        setInfoBottom(
          <div
            className="about__infoBottom"
            dangerouslySetInnerHTML={createMarkup(bioDeuBottom)}
          />
        );
      } else {
        setTitle("レオノーレ");
        setSubtitle("ビーガン認定栄養士");
        setInfoTop(
          <div className="" dangerouslySetInnerHTML={createMarkup(bioJpnTop)} />
        );
        setInfoBottom(
          <div
            className="about__infoBottom"
            dangerouslySetInnerHTML={createMarkup(bioJpnBottom)}
          />
        );
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [
    language,
    bioEngTop,
    bioJpnTop,
    bioJpnBottom,
    bioDeuTop,
    bioDeuBottom,
    bioEngBottom,
  ]);

  return (
    <div className="about">
      <div className="about__top">
        <picture>
          <source
            media="(min-width:1440px)"
            srcSet={aboutImg1440}
            className="about__img"
            alt="itadakihealthy vegan nutritionist"
          />
          <source
            media="(min-width:1000px)"
            srcSet={aboutImg1440}
            className="about__img"
            alt="itadakihealthy vegan nutritionist"
          />
          <source
            media="(min-width:600px)"
            srcSet={aboutImg1000}
            className="about__img"
            alt="itadakihealthy vegan nutritionist"
          />
          <img
            src={aboutImg600}
            className="about__img"
            alt="itadakihealthy vegan nutritionist"
          />
        </picture>
        <div className="about__title">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
      <div className="about__info">
        <div className="about__infoTop">
          <img
            src={holdinAppleSm}
            alt="women holding apple"
            className="about__infoImgSm"
          />
          {infoTop}
          <img
            src={holdingApple}
            alt="women holding apple"
            className="about__infoTopImg"
          />
        </div>
        {infoBottom}
        <Stamps stampsClass="stampsSmall" />
      </div>
      <div className="about__button">
        <SignUpButton buttonTextClass="signUpButton__text" />
      </div>
    </div>
  );
}

export default About;
