import React, { useState, useEffect } from "react";
import "./Concept.css";
import FourSteps from "../conceptComponents/FourSteps";
import SignUpButton from "../homeComponents/SignUpButton";
import conceptImg from "../images/bowl-veggies.jpeg";
import conceptImg1440 from "../images/bowl-veggies1440.jpeg";
import conceptImg1000 from "../images/bowl-veggies1000.jpeg";
import conceptImg600 from "../images/bowl-veggies600.jpeg";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";

function Concept() {
  const language = useSelector(selectLanguage);

  //translations
  const [title, setTitle] = useState();
  const [subtitle, setSubtitle] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setTitle("How it Works");
        setSubtitle("The itadakihealthy Approach");
      } else if (language === "deu") {
        setTitle("Konzept");
        setSubtitle("So läuft es ab");
      } else {
        setTitle("コンセプト");
        setSubtitle("itadakihealthyのアプローチ");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  return (
    <div className="concept">
      <div className="concept__top">
        <picture>
          <source
            media="(min-width:1440px)"
            srcSet={conceptImg}
            className="concept__img"
            alt="vegetables and fruit"
          />
          <source
            media="(min-width:1000px)"
            srcSet={conceptImg1440}
            className="concept__img"
            alt="vegetables and fruit"
          />
          <source
            media="(min-width:600px)"
            srcSet={conceptImg1000}
            className="concept__img"
            alt="vegetables and fruit"
          />
          <img
            src={conceptImg600}
            alt="vegetables and fruit"
            className="concept__img"
          />
        </picture>
        <div className="concept__title">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
      <FourSteps />
      <div className="concept__button">
        <SignUpButton buttonTextClass="signUpButton__text" />
      </div>
    </div>
  );
}

export default Concept;
