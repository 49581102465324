import React, { useState } from "react";
import createMarkup from "../utils/functions";

function Question({ question, answer }) {
  const [answerClass, setAnswerClass] = useState("noAnswer");

  return (
    <div className="question">
      <li className="question__listItem">
        <h3
          className="question__question"
          dangerouslySetInnerHTML={createMarkup(question)}
          onClick={() =>
            setAnswerClass(
              answerClass === "noAnswer" ? "question__answer" : "noAnswer"
            )
          }
        ></h3>
      </li>
      <div className={answerClass}>
        <p dangerouslySetInnerHTML={createMarkup(answer)}></p>
      </div>
    </div>
  );
}

export default Question;
