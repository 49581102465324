import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { db } from "../redux/firebase";
import createMarkup from "../utils/functions";
import { doc, updateDoc } from "firebase/firestore";

function HtmlItem({ string, title, id }) {
  const [editing, setEditing] = useState(false);
  const [editedText, setEditedText] = useState();

  useEffect(() => {
    setEditedText(string);
  }, [string]);

  const confirmSave = () => {
    confirmAlert({
      message: "Save changes to biography?",
      buttons: [
        {
          label: "Save",
          onClick: handleSave,
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleSave = async () => {
    const docRef = doc(db, "bio", "P9wwjlot7yDNy63STyx9");

    await updateDoc(docRef, {
      [id]: editedText,
    });
    setEditing(false);
  };

  return (
    <div className="adminItemContainer">
      {editing === true && (
        <div className="adminInput">
          <h3 className="adminItemTitle">{title}</h3>
          <textarea
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
          ></textarea>
          <div className="adminButtons">
            <button className="adminEdit" onClick={confirmSave}>
              Save
            </button>
            <button className="adminEdit" onClick={() => setEditing(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {editing === false && (
        <div className="adminItem">
          <h3 className="adminItemTitle">{title}</h3>
          <div
            className=""
            dangerouslySetInnerHTML={createMarkup(editedText)}
          />
          <div className="adminButtons">
            <button className="adminEdit" onClick={() => setEditing(true)}>
              Edit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default HtmlItem;
