import React, { useState, useEffect } from "react";
import { db } from "../redux/firebase";
import HtmlItem from "./HtmlItem";
import { collection, getDocs } from "firebase/firestore";

function AdminBio() {
  const [bioText, setBioText] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const querySnapShot = await getDocs(collection(db, "bio"));
      setBioText(
        querySnapShot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    };
    getData();
  }, [bioText]);

  return (
    <div>
      <h2 className="adminTitle">Biography</h2>
      {bioText.map(
        ({
          id,
          data: {
            bioEngTop,
            bioEngBottom,
            bioDeuTop,
            bioDeuBottom,
            bioJpnTop,
            bioJpnBottom,
          },
        }) => (
          <div key={id}>
            <HtmlItem
              id="bioEngTop"
              key="bioEngTop"
              string={bioEngTop}
              title="English Top Half"
            />
            <HtmlItem
              key="bioEngBottom"
              id="bioEngBottom"
              string={bioEngBottom}
              title="English Bottom Half"
            />
            <HtmlItem
              key="bioDeuTop"
              id="bioDeuTop"
              string={bioDeuTop}
              title="German Top Half"
            />
            <HtmlItem
              key="bioDeuBottom"
              id="bioDeuBottom"
              string={bioDeuBottom}
              title="German Bottom Half"
            />
            <HtmlItem
              key="bioJpnTop"
              id="bioJpnTop"
              string={bioJpnTop}
              title="Japanese Top Half"
            />
            <HtmlItem
              key="bioJpnBottom"
              id="bioJpnBottom"
              string={bioJpnBottom}
              title="Japanese Bottom Half"
            />
          </div>
        )
      )}
    </div>
  );
}

export default AdminBio;
