import React, { useState, useLayoutEffect } from "react";
import "./FourSteps.css";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import StepsItem from "./StepsItem.js";

//image imports
import leaf from "../images/leaf-cut-egg.png";
import caterpillar from "../images/caterpillar-cut.png";
import cocoon from "../images/cocoon.png";
import butterfly from "../images/butterfly-cut.png";
import leafSm from "../images/leaf-egg.png";
import caterpillarSm from "../images/caterpillar.svg";
import cocoonSm from "../images/cocoonSm.png";
import butterflySm from "../images/butterfly.svg";

//firebase imports
import { db } from "../redux/firebase";
import { doc, getDoc } from "firebase/firestore";

function FourSteps() {
  const language = useSelector(selectLanguage);

  //translations
  const [text, setText] = useState();
  const [itemTitle1, setItemTitle1] = useState();
  const [itemText1, setItemText1] = useState();
  const [itemTitle2, setItemTitle2] = useState();
  const [itemText2, setItemText2] = useState();
  const [itemTitle3, setItemTitle3] = useState();
  const [itemText3, setItemText3] = useState();
  const [itemTitle4, setItemTitle4] = useState();
  const [itemText4, setItemText4] = useState();

  useLayoutEffect(() => {
    const getData = async () => {
      if (language === "eng") {
        const docRef = doc(db, "howItWorks", "EL5p8gTzd9Mkwz6BEsnL");

        const docSnap = await getDoc(docRef);
        if (docSnap.exists) {
          setText(docSnap.data().introEng);
          setItemText1(docSnap.data().step1Eng);
          setItemText2(docSnap.data().step2Eng);
          setItemText3(docSnap.data().step3Eng);
          setItemText4(docSnap.data().step4Eng);
        } else {
          console.log("no such document");
        }
        setItemTitle1("Schedule your first consultation");
        setItemTitle2("Discovery discussion");
        setItemTitle3("Exploring new methods");
        setItemTitle4("A new lifestyle");
      } else if (language === "deu") {
        const docRef2 = doc(db, "howItWorksDeu", "Lk4ExMddVdEs5Wzc4z0J");

        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists) {
          setText(docSnap2.data().introDeu);
          setItemText1(docSnap2.data().step1Deu);
          setItemText2(docSnap2.data().step2Deu);
          setItemText3(docSnap2.data().step3Deu);
          setItemText4(docSnap2.data().step4Deu);
        } else {
          console.log("no such document");
        }
        setItemTitle1(" Kontaktiere itadakihealthy");
        setItemTitle2("Dein Erstgespräch");
        setItemTitle3("Entdecke neue Möglichkeiten");
        setItemTitle4("Dein neuer Lebensstil");
      } else {
        const docRef3 = doc(db, "howItWorksJpn", "Vrq649ErCQAAg2aiIfol");
        const docSnap3 = await getDoc(docRef3);

        if (docSnap3.exists) {
          setText(docSnap3.data().introJpn);
          setItemText1(docSnap3.data().step1Jpn);
          setItemText2(docSnap3.data().step2Jpn);
          setItemText3(docSnap3.data().step3Jpn);
          setItemText4(docSnap3.data().step4Jpn);
        } else {
          console.log("no such document");
        }

        setItemTitle1("連絡を取る");
        setItemTitle2("初めの相談");
        setItemTitle3("次のステップを考える");
        setItemTitle4("新しい生活");
      }
    };
    getData();
  }, [language, text]);
  return (
    <div className="fourSteps">
      <p>{text}</p>
      <StepsItem
        itemClass="stepsItem__right"
        imageContainerClass="stepsItem__imgOnLeft"
        image={leaf}
        itemTitle={itemTitle1}
        itemText={itemText1}
        imageClass="leafImg"
        imageSm={leafSm}
        imageSmClass="leafImgSm"
      />
      <StepsItem
        itemClass="stepsItem__left"
        image={caterpillar}
        itemTitle={itemTitle2}
        itemText={itemText2}
        imageContainerClass="stepsItem__imgOnRight"
        imageClass="caterpillarImg"
        imageSm={caterpillarSm}
        imageSmClass="caterpillarImgSm"
      />
      <StepsItem
        itemClass="stepsItem__right"
        image={cocoon}
        itemTitle={itemTitle3}
        itemText={itemText3}
        imageContainerClass="stepsItem__imgOnLeft"
        imageClass="cocoonImg"
        imageSm={cocoonSm}
        imageSmClass="cocoonImgSm"
      />
      <StepsItem
        itemClass="stepsItem__left"
        image={butterfly}
        itemTitle={itemTitle4}
        itemText={itemText4}
        imageContainerClass="stepsItem__imgOnRight"
        imageClass="butterflyImg"
        imageSm={butterflySm}
        imageSmClass="butterflyImgSm"
      />
    </div>
  );
}

export default FourSteps;
