import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { db } from "../redux/firebase";
import createMarkup from "../utils/functions";

function Question({
  question,
  answer,
  databaseName,
  questionId,
  deleteQuestionFromDb,
}) {
  const [editingQuestion, setEditingQuestion] = useState(false);
  const [editingAnswer, setEditingAnswer] = useState(false);
  const [questionText, setQuestionText] = useState(question);
  const [answerText, setAnswerText] = useState(answer);

  const confirmDelete = () => {
    confirmAlert({
      message: "Are you sure?",
      buttons: [
        {
          label: "Delete",
          onClick: handleDelete,
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const confirmEditQuestion = () => {
    if (questionText !== "") {
      confirmAlert({
        message: "Save changes?",
        buttons: [
          {
            label: "Save",
            onClick: handleUpdateQuestion,
          },
          {
            label: "Cancel",
          },
        ],
      });
    } else {
      alert("Cannot update a blank question");
    }
  };

  const confirmEditAnswer = () => {
    if (answerText !== "") {
      confirmAlert({
        message: "Save changes?",
        buttons: [
          {
            label: "Save",
            onClick: handleUpdateAnswer,
          },
          {
            label: "Cancel",
          },
        ],
      });
    } else {
      alert("Cannot update a blank answer");
    }
  };

  const handleDelete = () => {
    deleteQuestionFromDb(databaseName, questionId);
  };

  const handleUpdateQuestion = async () => {
    const docRef = doc(db, databaseName, questionId);

    await updateDoc(docRef, {
      question: questionText,
    });
    setEditingQuestion(false);
  };

  const handleUpdateAnswer = async () => {
    const docRef = doc(db, databaseName, questionId);

    await updateDoc(docRef, {
      answer: answerText,
    });
    setEditingAnswer(false);
  };
  return (
    <div className="adminItemContainer">
      {editingQuestion === true && (
        <div className="adminInput">
          <h3 className="adminItemTitle">Question</h3>
          <textarea
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          ></textarea>
          <button className="adminEdit" onClick={confirmEditQuestion}>
            Save Changes
          </button>
          <button
            className="adminEdit"
            onClick={() => setEditingQuestion(false)}
          >
            Cancel
          </button>
        </div>
      )}
      {editingQuestion === false && (
        <div className="adminItem">
          <h3 className="adminItemTitle">Question</h3>
          <div className="" dangerouslySetInnerHTML={createMarkup(question)} />
          <div className="adminButtons">
            <button
              className="adminEdit"
              onClick={() => setEditingQuestion(true)}
            >
              Edit
            </button>
            <button className="adminDelete" onClick={confirmDelete}>
              X
            </button>
          </div>
        </div>
      )}
      {editingAnswer === true && (
        <div className="adminInput">
          <h3 className="adminItemTitle">Answer</h3>
          <textarea
            value={answerText}
            onChange={(e) => setAnswerText(e.target.value)}
          ></textarea>
          <button className="adminEdit" onClick={confirmEditAnswer}>
            Save Changes
          </button>
          <button className="adminEdit" onClick={() => setEditingAnswer(false)}>
            Cancel
          </button>
        </div>
      )}
      {editingAnswer === false && (
        <div className="adminItem">
          <h3 className="adminItemTitle">Answer</h3>
          <div className="" dangerouslySetInnerHTML={createMarkup(answer)} />
          <div className="adminButtons">
            <button
              className="adminEdit"
              onClick={() => setEditingAnswer(true)}
            >
              Edit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Question;
