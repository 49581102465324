import React, { useState, useEffect } from "react";
import "./Contact.css";
//import contactImg from "../images/contactImg.jpg";
import contactImg1440 from "../images/contactImg1440.jpeg";
//import contactImg1000 from "../images/contactImg1000.jpeg";
//import contactImg600 from "../images/contactImg600.jpeg";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import ContactForm from "../components/ContactForm";

function Contact() {
  const language = useSelector(selectLanguage);

  //translations
  const [title, setTitle] = useState();
  const [subtitle, setSubtitle] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setTitle("Get Started");
        setSubtitle("Now is your Chance");
      } else if (language === "deu") {
        setTitle(" Los geht's! ");
        setSubtitle("Mach den ersten Schritt");
      } else {
        setTitle("お問い合わせ");
        setSubtitle("チャンスを掴みましょう");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);
  return (
    <div className="contact">
      <div className="contact__top">
        {/* <picture>
          <source
            media="(min-width:1440px)"
            srcSet={contactImg1440}
            className="contact__img"
          />
          <source
            media="(min-width:1000px)"
            srcSet={contactImg1440}
            className="contact__img"
          />
          <source
            media="(min-width:600px)"
            srcSet={contactImg1000}
            className="contact__img"
          />
          <img
            src={contactImg1440}
            alt="about itadakihealthy's nutritionist"
            className="contact__img"
          />
        </picture> */}
        <img
          src={contactImg1440}
          alt="about itadakihealthy's nutritionist"
          className="contact__img"
        />
        <div className="contact__title">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default Contact;
