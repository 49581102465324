import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { auth } from "../redux/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { login } from "../redux/userSlice";
import "./Login.css";

function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassWord] = useState();

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userAuth) => {
        dispatch(
          login({
            user: "admin",
          })
        );
      })

      .catch((error) => alert(error));
  };

  return (
    <div className="login">
      <div className="login__form">
        <input
          type="email"
          className="login__email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          className="login__password"
          onChange={(e) => setPassWord(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
}

export default Login;
