import React, { useState, useEffect } from "react";
import japanese from "../images/japan.svg";
import german from "../images/germany.svg";
import english from "../images/united-kingdom.svg";
import "./Header.css";
import { useHistory } from "react-router-dom";
import logo from "../images/logo-darkgreen.png";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage, changeLanguage } from "../redux/languageSlice";
import Menu from "./Menu";

function Header({ menuId }) {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const history = useHistory();

  const [burgerClass, setBurgerClass] = useState("");
  const [menuClass, setMenuClass] = useState("menuBefore");

  //translations
  const [home, setHome] = useState();
  const [nutritionist, setNutritionist] = useState();
  const [concept, setConcept] = useState();
  const [getStarted, setGetStarted] = useState();
  const [faq, setFaq] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setHome("Home");
        setNutritionist("Nutrition Coach");
        setConcept("Concept");
        setGetStarted("Get Started");
        setFaq("FAQ");
      } else if (language === "deu") {
        setHome("Startseite");
        setNutritionist(" Über mich");
        setConcept("Konzept");
        setGetStarted("Kontakt");
        setFaq("Häufig gestellte Fragen");
      } else {
        setHome("ホーム");
        setNutritionist("自己紹介");
        setConcept("コンセプト");
        setGetStarted("お問い合わせ");
        setFaq("よくある質問");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  const changeToJpn = () => {
    dispatch(changeLanguage("jpn"));
  };

  const changeToDeu = () => {
    dispatch(changeLanguage("deu"));
  };

  const changeToEng = () => {
    dispatch(changeLanguage("eng"));
  };

  const handleShowMenu = () => {
    setMenuClass(menuClass === "menu" ? "menuBefore" : "menu");
    setBurgerClass(burgerClass === "open" ? "" : "open");
    console.log("close menu");
  };

  return (
    <div className="header">
      <div className="header__left">
        <img src={logo} alt="itadakihealthy logo" className="header__logo" />
        <div className="header__options">
          <p onClick={() => history.push("/")}>{home}</p>
          <p onClick={() => history.push("/about")}>{nutritionist}</p>
          <p onClick={() => history.push("/concept")}>{concept}</p>
          <p onClick={() => history.push("/contact")}>{getStarted}</p>
          <p onClick={() => history.push("/faq")}>{faq}</p>
        </div>
      </div>
      <div className="header__right">
        <img
          src={japanese}
          alt="japan flag"
          className="header__flag"
          onClick={changeToJpn}
        />
        <img
          src={german}
          alt="german flag"
          className="header__flag"
          onClick={changeToDeu}
        />
        <img
          src={english}
          alt="british flag"
          className="header__flag"
          onClick={changeToEng}
        />
        <div id="nav-icon" onClick={handleShowMenu} className={burgerClass}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <Menu
        home={home}
        about={nutritionist}
        concept={concept}
        contact={getStarted}
        history={history}
        menuClass={menuClass}
        faq={faq}
        menuId={menuId}
        handleShowMenu={handleShowMenu}
      />
    </div>
  );
}

export default Header;
