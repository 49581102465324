import React, { useEffect, useState } from "react";
import { db } from "../redux/firebase";
import AdminStep from "./AdminStep";
import { doc, getDoc } from "firebase/firestore";

function AdminHiw() {
  const [textEng, setTextEng] = useState();
  const [textDeu, setTextDeu] = useState();
  const [textJpn, setTextJpn] = useState();
  const [step1Eng, setStep1Eng] = useState();
  const [step1Deu, setStep1Deu] = useState();
  const [step1Jpn, setStep1Jpn] = useState();
  const [step2Eng, setStep2Eng] = useState();
  const [step2Deu, setStep2Deu] = useState();
  const [step2Jpn, setStep2Jpn] = useState();
  const [step3Eng, setStep3Eng] = useState();
  const [step3Deu, setStep3Deu] = useState();
  const [step3Jpn, setStep3Jpn] = useState();
  const [step4Eng, setStep4Eng] = useState();
  const [step4Deu, setStep4Deu] = useState();
  const [step4Jpn, setStep4Jpn] = useState();

  useEffect(() => {
    const getData = async () => {
      const docRef1 = doc(db, "howItWorks", "EL5p8gTzd9Mkwz6BEsnL");
      const docSnap1 = await getDoc(docRef1);
      if (docSnap1.exists) {
        setTextEng(docSnap1.data().introEng);
        setStep1Eng(docSnap1.data().step1Eng);
        setStep2Eng(docSnap1.data().step2Eng);
        setStep3Eng(docSnap1.data().step3Eng);
        setStep4Eng(docSnap1.data().step4Eng);
      }

      const docRef2 = doc(db, "howItWorksDeu", "Lk4ExMddVdEs5Wzc4z0J");
      const docSnap2 = await getDoc(docRef2);
      if (docSnap2.exists) {
        setTextDeu(docSnap2.data().introDeu);
        setStep1Deu(docSnap2.data().step1Deu);
        setStep2Deu(docSnap2.data().step2Deu);
        setStep3Deu(docSnap2.data().step3Deu);
        setStep4Deu(docSnap2.data().step4Deu);
      }

      var docRef3 = doc(db, "howItWorksJpn", "Vrq649ErCQAAg2aiIfol");
      const docSnap3 = await getDoc(docRef3);
      if (docSnap3.exists) {
        setTextJpn(docSnap3.data().introJpn);
        setStep1Jpn(docSnap3.data().step1Jpn);
        setStep2Jpn(docSnap3.data().step2Jpn);
        setStep3Jpn(docSnap3.data().step3Jpn);
        setStep4Jpn(docSnap3.data().step4Jpn);
      }
    };

    getData();
  }, [
    textEng,
    textDeu,
    textJpn,
    step1Eng,
    step2Eng,
    step3Eng,
    step4Eng,
    step1Deu,
    step2Deu,
    step3Deu,
    step4Deu,
    step4Deu,
    step1Jpn,
    step2Jpn,
    step3Jpn,
    step4Jpn,
  ]);

  return (
    <div>
      <h2 className="adminTitle">How It Works/Concept</h2>
      <AdminStep
        collection="howItWorks"
        content={textEng}
        title="English Intro"
        docName="EL5p8gTzd9Mkwz6BEsnL"
        id="introEng"
      />
      <AdminStep
        collection="howItWorksDeu"
        content={textDeu}
        title="German Intro"
        docName="Lk4ExMddVdEs5Wzc4z0J"
        id="introDeu"
      />
      <AdminStep
        collection="howItWorksJpn"
        content={textJpn}
        title="Japanese Intro"
        docName="Vrq649ErCQAAg2aiIfol"
        id="introJpn"
      />
      <AdminStep
        collection="howItWorks"
        content={step1Eng}
        title="English Step 1"
        docName="EL5p8gTzd9Mkwz6BEsnL"
        id="step1Eng"
      />
      <AdminStep
        collection="howItWorksDeu"
        content={step1Deu}
        title="German Step 1"
        docName="Lk4ExMddVdEs5Wzc4z0J"
        id="step1Deu"
      />
      <AdminStep
        collection="howItWorksJpn"
        content={step1Jpn}
        title="Japanese Step 1"
        docName="Vrq649ErCQAAg2aiIfol"
        id="step1Jpn"
      />
      <AdminStep
        collection="howItWorks"
        content={step2Eng}
        title="English Step 2"
        docName="EL5p8gTzd9Mkwz6BEsnL"
        id="step2Eng"
      />
      <AdminStep
        collection="howItWorksDeu"
        content={step2Deu}
        title="German Step 2"
        docName="Lk4ExMddVdEs5Wzc4z0J"
        id="step2Deu"
      />
      <AdminStep
        collection="howItWorksJpn"
        content={step2Jpn}
        title="Japanese Step 2"
        docName="Vrq649ErCQAAg2aiIfol"
        id="step2Jpn"
      />
      <AdminStep
        collection="howItWorks"
        content={step3Eng}
        title="English Step 3"
        docName="EL5p8gTzd9Mkwz6BEsnL"
        id="step3Eng"
      />
      <AdminStep
        collection="howItWorksDeu"
        content={step3Deu}
        title="German Step 3"
        docName="Lk4ExMddVdEs5Wzc4z0J"
        id="step3Deu"
      />
      <AdminStep
        dminStep
        collection="howItWorksJpn"
        content={step3Jpn}
        title="Japanese Step 3"
        docName="Vrq649ErCQAAg2aiIfol"
        id="step3Jpn"
      />
      <AdminStep
        collection="howItWorks"
        content={step4Eng}
        title="English Step 4"
        docName="EL5p8gTzd9Mkwz6BEsnL"
        id="step4Eng"
      />
      <AdminStep
        collection="howItWorksDeu"
        content={step4Deu}
        title="German Step 4"
        docName="Lk4ExMddVdEs5Wzc4z0J"
        id="step4Deu"
      />
      <AdminStep
        collection="howItWorksJpn"
        content={step4Jpn}
        title="Japanese Step 4"
        docName="Vrq649ErCQAAg2aiIfol"
        id="step4Jpn"
      />
    </div>
  );
}

export default AdminHiw;
