import React from "react";
import "./BoardItem.css";

function BoardItem({ icon, title, text, itemClass }) {
  return (
    <div className={itemClass}>
      <img src={icon} alt="health-related icon" className="boardItem__img" />
      <h3 className="boardItem__title">{title}</h3>
      <p className="boardItem__text">{text}</p>
    </div>
  );
}

export default BoardItem;
