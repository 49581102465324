import React, { useState } from "react";
import "./Admin.css";
import { useDispatch } from "react-redux";
import { logout } from "../redux/userSlice";
import { auth } from "../redux/firebase";
import AdminFaq from "./AdminFaq";
import AdminBio from "./AdminBio";
import AdminHiw from "./AdminHiw";
import { signOut } from "firebase/auth";

function Admin() {
  const dispatch = useDispatch();
  const [adminBody, setAdminBody] = useState("faq");

  const handleLogout = () => {
    dispatch(logout());
    signOut(auth);
  };

  return (
    <div>
      <div className="admin__header">
        <p className="admin__option" onClick={() => setAdminBody("faq")}>
          FAQ
        </p>
        <p className="admin__option" onClick={() => setAdminBody("bio")}>
          Bio
        </p>
        <p className="admin__option" onClick={() => setAdminBody("hiw")}>
          HiW
        </p>
        <p className="admin__option" onClick={handleLogout}>
          Logout
        </p>
      </div>
      <div className="admin__body">
        {adminBody === "faq" && <AdminFaq />}
        {adminBody === "bio" && <AdminBio />}
        {adminBody === "hiw" && <AdminHiw />}
      </div>
    </div>
  );
}

export default Admin;
