import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import BoardItem from "./BoardItem";
import "./Board.css";
import adviceIcon from "../images/advice-brown.svg";
import dietIcon from "../images/diet-brown.svg";
import healthcareIcon from "../images/healthcare-brown.svg";
import healthyIcon from "../images/healthy-living-brown.svg";
import nutrientsIcon from "../images/nutrients-brown.svg";
import strongIcon from "../images/strong-brown.svg";
import eatHealthierIcon from "../images/eatHealthier.svg";
import habitsIcon from "../images/habits.svg";

function Board() {
  const language = useSelector(selectLanguage);

  //translations
  const [title1, setTitle1] = useState();
  const [text1, setText1] = useState();
  const [title2, setTitle2] = useState();
  const [text2, setText2] = useState();
  const [title3, setTitle3] = useState();
  const [text3, setText3] = useState();
  const [title4, setTitle4] = useState();
  const [text4, setText4] = useState();
  const [title5, setTitle5] = useState();
  const [text5, setText5] = useState();
  const [title6, setTitle6] = useState();
  const [text6, setText6] = useState();
  const [title7, setTitle7] = useState();
  const [text7, setText7] = useState();
  const [title8, setTitle8] = useState();
  const [text8, setText8] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setTitle1("Stressfree Advice ");
        setText1(
          "Get tailored, professional advice from a certified nutritionist."
        );
        setTitle2("Prevent Diseases");
        setText2(
          "Reduce the risk of diet-related diseases like type 2 diabetes, heart disease, and gout."
        );
        setTitle3("Increase your Energy");
        setText3(
          "Learn how to boost your energy with a healthy vegan diet, and avoid feeling sluggish or tired throughout the day."
        );
        setTitle4("Eat healthier");
        setText4(
          "Feel happier and healthier on a diet that is not only valuable to you, but also has a positive effect on future generations and the planet."
        );
        setTitle5("Get your Nutrients");
        setText5(
          "Get all your nutrients whether you're an athlete, pregnant or breastfeeding, a desk worker, or a homemaker. "
        );
        setTitle6("Create Habits");
        setText6(
          "Create lasting healthy eating habits to keep yourself healthy and happy in the long term. "
        );
        setTitle7("Feel Better");
        setText7(
          "Feel better in your body by giving it what it needs to make you feel your best."
        );
        setTitle8("Lose Weight");
        setText8(
          "Adopting a healthy vegan diet with the help of a certified nutritionist is a great way to get started or continue on your weightloss journey. "
        );
      } else if (language === "deu") {
        setTitle1("Stressfreie Beratung");
        setText1(
          "Speziell an deine individuellen Umstände und Bedürfnisse angepasst."
        );
        setTitle2("Beuge Krankheiten vor");
        setText2(
          <p>
            Mindere das Risiko für ernährungs
            <wbr />
            bedingte Krankheiten wie Diabetes Typ 2, koronare Herzkrankheiten
            oder Gicht.
          </p>
        );
        setTitle3("Mehr Energie");
        setText3(
          "Lerne, dein Energielevel zu verstärken mit der für dich richtigen veganen Ernährung, und verliere das Gefühl ständiger Müdigkeit."
        );
        setTitle4("Ernähre dich gesünder");
        setText4(
          "Finde eine Ernährung die zu dir passt, sich positiv auf dein Wohlbefinden auswirkt und für unseren Planeten nachhaltig ist."
        );
        setTitle5(
          <h3>
            Nährstoff-
            <br />
            versorgung
          </h3>
        );
        setText5(
          "(Nicht-) Sportler, schwanger oder stillend: stelle eine ausreichende Versorgung mit allen essentiellen Nährstoffen in deinem Alltag sicher."
        );
        setTitle6("Schaffe Gewohnheiten");
        setText6(
          "Finde deine dauerhaft gesunde Ernährungsweise, um langanhaltend gesund und munter zu bleiben."
        );
        setTitle7("Fühle dich  rundum wohl");
        setText7("Gib deinem Körper, was er braucht, um dich wohl zu fühlen.");
        setTitle8("Verliere Gewicht");
        setText8(
          <p>
            Finde zusammen mit deiner Ernährungs
            <wbr />
            beratung deinen individuellen, gesunden Weg zu deinem
            Wohlfühlgewicht.
          </p>
        );
      } else {
        setTitle1("ストレスフリー");
        setText1("認定栄養士から、あなただけの専門的なアドバイスを受られる。");
        setTitle2("病気の予防。");
        setText2("あなたのitadakihealthy栄養士と一緒に生活習慣病を防ぐ。");
        setTitle3("より健康的に");
        setText3(
          "健康的なビーガン食でエネルギーを高める方法を学ぶ。疲れを感じない体になる。"
        );
        setTitle4("健康的な食事");
        setText4(
          "あなたにとって価値があるだけでなく、将来の世代と地球にプラスの効果をもたらす食事療法で、より幸せで健康に感じる。"
        );
        setTitle5("栄養を取る");
        setText5(
          "妊婦、選手、サラリーマン、母親、子供、高齢者。。。どの生活にも必要な栄養が取れる。"
        );
        setTitle6("良い習慣を作る");
        setText6("認定栄養士と一緒に長期的で健康に良い習慣を作る。");
        setTitle7("体がすっきり軽くなる");
        setText7("必要な栄養素を取れるので元気な体になる。");
        setTitle8("理想的な体重に");
        setText8("ビーガンの食事は健康にいい瘦せ方のひとつです。");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  return (
    <div className="board">
      <div className="board__top">
        <div className="board__topLeft">
          <BoardItem
            icon={adviceIcon}
            title={title1}
            text={text1}
            itemClass="boardItem__white"
          />
          <BoardItem
            icon={healthcareIcon}
            title={title2}
            text={text2}
            itemClass="boardItem__lightgreen"
          />
        </div>
        <div className="board__topRight">
          <BoardItem
            icon={strongIcon}
            title={title3}
            text={text3}
            itemClass="boardItem__green"
          />
          <BoardItem
            icon={eatHealthierIcon}
            title={title4}
            text={text4}
            itemClass="boardItem__white"
          />
        </div>
      </div>
      <div className="board__bottom">
        <div className="board__bottomLeft">
          <BoardItem
            icon={nutrientsIcon}
            title={title5}
            text={text5}
            itemClass="boardItem__lightgreen"
          />
          <BoardItem
            icon={habitsIcon}
            title={title6}
            text={text6}
            itemClass="boardItem__green"
          />
        </div>
        <div className="board__bottomRight">
          <BoardItem
            icon={healthyIcon}
            title={title7}
            text={text7}
            itemClass="boardItem__white"
          />
          <BoardItem
            icon={dietIcon}
            title={title8}
            text={text8}
            itemClass="boardItem__lightgreen"
          />
        </div>
      </div>
    </div>
  );
}

export default Board;
