import React, { useState, useEffect } from "react";
import { selectLanguage } from "../redux/languageSlice";
import { useSelector } from "react-redux";
import "./SignUpButton.css";
import { useHistory } from "react-router-dom";

function SignUpButton({ buttonTextClass }) {
  const language = useSelector(selectLanguage);
  const history = useHistory();

  const [text, setText] = useState();
  const [buttonText, setButtonText] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setText("Get started with a 30-minute consultation");
        setButtonText("Get in touch");
      } else if (language === "deu") {
        setText("Hier geht es zu deiner Erstgesprächsbuchung");
        setButtonText("Loslegen");
      } else {
        setText("30分の相談から始めましょう");
        setButtonText("サインアップ");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  return (
    <div className="signUpButton">
      <h3 className={buttonTextClass}>{text}</h3>
      <button onClick={() => history.push("/contact")}>{buttonText}</button>
    </div>
  );
}

export default SignUpButton;
