import React from 'react'

function StepsItem({ imageClass, image, itemClass, imageContainerClass, itemTitle, itemText, imageSm, imageSmClass}) {
    return (
        <div className={itemClass}>
            <div className={imageContainerClass}>
                <img src={image} alt="bugs" className={imageClass}/>
                <img src={imageSm} alt="bugs" className={imageSmClass}/>
            </div>
            <div className="stepsItem__text">
                <h2 className="stepsItem__title">{itemTitle}</h2>
                <p>{itemText}</p>
            </div>
        </div>
    )
}

export default StepsItem
