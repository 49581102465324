import React, { useEffect, useState } from "react";
import { db } from "../redux/firebase";
import {
  doc,
  updateDoc,
  deleteField,
  deleteDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import Question from "./Question";
import AddNew from "./AddNew";

function AdminFaq() {
  const [questionsEng, setQuestionsEng] = useState([]);
  const [questionsDeu, setQuestionsDeu] = useState([]);
  const [questionsJpn, setQuestionsJpn] = useState([]);
  const [addQuestion, setAddQuestion] = useState(false);

  const deleteQuestionFromDb = async (collectionName, questionId) => {
    const dataRef = doc(db, collectionName, questionId);

    await updateDoc(dataRef, {
      question: deleteField(),
      answer: deleteField(),
    });

    await deleteDoc(doc(db, collectionName, questionId));
  };

  useEffect(() => {
    const unsubsribe = async () => {
      const querySnapShot1 = await getDocs(collection(db, "faqEng"));
      setQuestionsEng(
        querySnapShot1.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
      const querySnapShot2 = await getDocs(collection(db, "faqDeu"));
      setQuestionsDeu(
        querySnapShot2.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
      const querySnapShot3 = await getDocs(collection(db, "faqJpn"));
      setQuestionsJpn(
        querySnapShot3.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }))
      );
    };
    unsubsribe();
  }, [questionsJpn.data, questionsDeu.data, questionsEng.data]);

  return (
    <div>
      <h2 className="adminTitle">FAQ</h2>
      <h4 className="adminAddNew" onClick={() => setAddQuestion(!addQuestion)}>
        {addQuestion ? "Cancel" : "Add new question"}
      </h4>

      {addQuestion && <AddNew />}

      {questionsEng.map(({ id, data: { question, answer } }) => (
        <Question
          key={id}
          questionId={id}
          question={question}
          answer={answer}
          databaseName="faqEng"
          deleteQuestionFromDb={deleteQuestionFromDb}
        />
      ))}
      {questionsDeu.map(({ id, data: { question, answer } }) => (
        <Question
          key={id}
          questionId={id}
          question={question}
          answer={answer}
          databaseName="faqDeu"
          deleteQuestionFromDb={deleteQuestionFromDb}
        />
      ))}
      {questionsJpn.map(({ id, data: { question, answer } }) => (
        <Question
          key={id}
          questionId={id}
          question={question}
          answer={answer}
          databaseName="faqJpn"
          deleteQuestionFromDb={deleteQuestionFromDb}
        />
      ))}
    </div>
  );
}

export default AdminFaq;
