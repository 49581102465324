import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import Header from "./components/Header";
import About from "./pages/About";
import Footer from "./components/Footer";
import Concept from "./pages/Concept";
import Contact from "./pages/Contact";
import "./App.css";
import { useSelector } from "react-redux";
import { changeLanguage, selectLanguage } from "./redux/languageSlice";
import { useDispatch } from "react-redux";
import FAQ from "./pages/FAQ";
import Admin from "./admin/Admin";
import { selectUser, login, logout } from "./redux/userSlice";
import { auth } from "./redux/firebase";
import Login from "./admin/Login";
import PromoBar from "./components/PromoBar";
//import logo from "./images/logo-2.png";

function App() {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const user = useSelector(selectUser);

  const [aboutTitle, setAboutTitle] = useState(
    "itadakihealthy |Your Nutritionist"
  );
  const [homeTitle, setHomeTitle] = useState("itadakihealthy | Home");
  const [conceptTitle, setConceptTitle] = useState(
    "itadakihealthy | How it Works"
  );
  const [contactTitle, setContactTitle] = useState(
    "itadakihealthy | Get in Touch"
  );
  const [faqTitle, setFaqTitle] = useState("itadakihealthy | FAQ");
  const [homeContent, setHomeContent] = useState(
    "The itadakihealthy mission: helping YOU to create your personal and very own healthy lifestyle by providing you with helpful plant-based nutritional know-how (regardless of whether or not you’re vegan). Based in Tokyo, Japan."
  );
  const [aboutContent, setAboutContent] = useState(
    "I’m Leonore, a certified vegan nutritionist from Germany, who moved to vibrant Tokyo in the 2014 after finishing my degree in Japanese studies at Frankfurt Goethe University."
  );
  const [conceptContent, setConceptContent] = useState(
    "Itadakihealthy is a blend of the words itadakimasu and healthy. In Japanese, “itadakimasu” means “ready to receive (food)”. Just as easily as these two words can be combined, you can also combine enjoyable and delicious food with good nutrition. Here is how itadakihealthy works"
  );
  const [contactContent, setContactContent] = useState("");
  const [faqContent, setFaqContent] = useState(
    "Just fill out the contact form and your vegan nutritionist will be in touch."
  );
  const [siteContent, setSiteContent] = useState(
    "Find answers to commong questions concerning itadakihealthy."
  );
  const [siteTitle, setSiteTitle] = useState("itadakihealthy");

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (navigator.language.includes("en")) {
        dispatch(changeLanguage("eng"));
      } else if (navigator.language.includes("jp")) {
        dispatch(changeLanguage("jpn"));
      } else if (navigator.language.includes("de")) {
        dispatch(changeLanguage("deu"));
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setSiteTitle("itadakihealthy: Vegan Nutrition Coaching");
        setSiteContent(
          "The itadakihealthy mission: helping YOU to create your personal and very own healthy lifestyle by providing you with helpful plant-based nutritional know-how (regardless of whether or not you’re vegan). Based in Tokyo, Japan."
        );
        setAboutTitle("itadakihealthy |Your Nutritionist");
        setHomeTitle("itadakihealthy | Home");
        setConceptTitle("itadakihealthy | How it Works");
        setContactTitle("itadakihealthy | Get in Touch");
        setFaqTitle("itadakihealthy | FAQ");
        setHomeContent(
          "Stressfree advice, prevent disease, increase you energy, eat healthier, get your nutrients..."
        );
        setAboutContent(
          "I’m Leonore, a certified vegan nutritionist from Germany, who moved to vibrant Tokyo in the 2014 after finishing my degree in Japanese studies at Frankfurt Goethe University."
        );
        setConceptContent(
          "Itadakihealthy is a blend of the words itadakimasu and healthy. In Japanese, “itadakimasu” means “ready to receive (food)”. Just as easily as these two words can be combined, you can also combine enjoyable and delicious food with good nutrition. Here is how itadakihealthy works:"
        );
        setContactContent(
          "Just fill out the contact form and your vegan nutritionist will be in touch."
        );
        setFaqContent(
          "Find answers to commong questions concerning itadakihealthy."
        );
      } else if (language === "deu") {
        setSiteTitle("itadakihealthy: Vegane Ernährungsberatung");
        setSiteContent(
          "Jeden Tag gesund essen ist möglich, mit dem richtigen know-how einfach umgesetzt und nicht nur dein Körper, auch die Umwelt wird es (dir) danken."
        );
        setAboutTitle("itadakihealthy | Über mich");
        setHomeTitle("itadakihealthy | Startseite");
        setConceptTitle("itadakihealthy | Konzept");
        setContactTitle("itadakihealthy | Kontakt");
        setFaqTitle("itadakihealthy | Häufig gestellte Fragen");
        setHomeContent(
          "Stressfreie Beratung, Beuge Krankheiten vor, Mehr Energie, Ernähre dich gesünder..."
        );
        setAboutContent(
          "Ich bin Leonore und komme aus Hessen in Deutschland. Nach meinem Japanologie-Studium bin ich nach Japan ausgewandert und wohne seit Sommer 2014 in Tokyo."
        );
        setConceptContent(
          "itadakihealthy ist aus zwei Wörtern zusammengesetzt: itadakimasu (jap.) wird täglich vor jedem Essen gesagt und drückt Dankbarkeit gegenüber der Speise aus. healthy (eng.) bedeutet gesund. Kombiniert ergibt sich itadakihealthy: jeden Tag gesund essen ist möglich, mit dem richtigen know-how einfach umgesetzt und nicht nur dein Körper, auch die Umwelt wird es (dir) danken."
        );
        setContactContent(
          "Trage dich in das Kontaktformular ein und ich werde dich in Kürze kontaktieren."
        );
        setFaqContent(
          "Für wen ist itadakihealthys Ernährungsberatung geeignet? Wie zeitaufwändig ist die Ernährungsberatung? Wie viele Termine sollte ich einplanen? Übernimmt die Krankenversicherung die Kosten für eine Beratung"
        );
      } else {
        setSiteTitle("itadakihealthy: ビーガン栄養コーチング");
        setSiteContent(
          "認定栄養士から、あなただけの専門的な栄養アドバイスを受られる。"
        );
        setAboutTitle("itadakihealthy | 栄養士紹介");
        setHomeTitle("itadakihealthy | ホーム");
        setConceptTitle("itadakihealthy | コンセプト");
        setContactTitle("itadakihealthy | お問い合わせ");
        setFaqTitle("itadakihealthy | よくある質問");
        setHomeContent(
          "ストレスフリー、 病気の予防、より健康的に、健康的な食事。。。"
        );
        setAboutContent(
          "はじめまして、私はレオノーレです、itadakihealthyの認定栄養士です。出身はドイツのヘッセン市です。2014年、 フランクフルトゲーテ大学で日本研究の学位を取得後、東京に来ました。"
        );
        setConceptContent(
          "itadakihealthyは日本語の「頂き」と英語の「healthy」から作られました。二つの単語をくっつけたように，美味しい料理と豊富な栄養を合わせることも簡単にできます．itadakihealthyのコンセプトをもっと詳しく知るためには、以下の説明をご覧ください。"
        );
        setContactContent(
          "お問い合わせフォームを記入するだけで、あなたのビーガン栄養士から連絡が届きます。"
        );
        setFaqContent(
          "itadakihealthyは健康保険を使用出来ますか？ アレルギーや食物不耐性のある人はitadakihealthyの栄養アドバイスを受けることが出来ますか？ プラントベースの食事のメリットは何ですか？　知っておくべき栄養の知識はありますか？"
        );
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        dispatch(
          login({
            email: userAuth.email,
            user: "admin",
          })
        );
      } else {
        dispatch(logout());
      }
    });
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <div className="app">
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteContent} />
          <meta name="og:description" content={siteContent} />
          <meta
            name="og:image"
            content="https://www.itadakihealthy.com/static/media/logo-darkgreen.d55c3f6b699d5999e361.png"
          />
          <meta
            name="image"
            content="https://www.itadakihealthy.com/static/media/logo-darkgreen.d55c3f6b699d5999e361.png"
          />
        </Helmet>
        <Switch>
          <Route path="/admin">{user ? <Admin /> : <Login />}</Route>
          <Route path="/about">
            <Helmet>
              <title>{aboutTitle}</title>
              <meta name="description" content={aboutContent} />
            </Helmet>
            <PromoBar />
            <Header menuId="menuGreen" />
            <About />
            <Footer />
          </Route>
          <Route path="/contact">
            <Helmet>
              <title>{contactTitle}</title>
              <meta name="description" content={contactContent} />
            </Helmet>
            <PromoBar />
            <Header menuId="menuBeige" />
            <Contact />
            <Footer />
          </Route>
          <Route path="/concept">
            <Helmet>
              <title>{conceptTitle}</title>
              <meta name="description" content={conceptContent} />
            </Helmet>
            <PromoBar />
            <Header menuId="menuGreen" />
            <Concept />
            <Footer />
          </Route>
          <Route path="/faq">
            <Helmet>
              <title>{faqTitle}</title>
              <meta name="description" content={faqContent} />
            </Helmet>
            <PromoBar />
            <Header menuId="menuBeige" />
            <FAQ />
            <Footer />
          </Route>
          <Route path="/">
            <Helmet>
              <title>{homeTitle}</title>
              <meta name="description" content={homeContent} />
            </Helmet>
            <PromoBar />
            <Header menuId="menuBeige" />
            <Home />
            <Footer />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
