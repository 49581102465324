import React from 'react'

function Item(props) {
    return (
        <div className="item">
           <picture >
                <source media="(min-width:1440px)" srcSet={props.item.image} className="item__img" alt="itadakihealthy home"/>
                <source media="(min-width:1000px)" srcSet={props.item.image1440} className="item__img" alt="itadakihealthy home"/>
                <source media="(min-width:600px)" srcSet={props.item.image1000} className="item__img" alt="itadakihealthy home"/>
                <img src={props.item.image600} className="item__img" alt="itadakihealthy home"/>
            </picture>
           
           <div className="item__title">
                <h1>{props.item.title}</h1>
                <p>{props.item.subtitle}</p>
           </div>
           
        </div>
    )
}

export default Item
