import React, { useState, useEffect } from "react";
import leaf from "../images/butterfly.svg";
import butterfly from "../images/leaf.svg";
import leafs from "../images/caterpillar.svg";
import { selectLanguage } from "../redux/languageSlice";
import { useSelector } from "react-redux";

function BotCheck({ setBotCheck }) {
  const language = useSelector(selectLanguage);

  const [imageClass, setImageClass] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setText("Please click on the butterfly");
      } else if (language === "deu") {
        setText("Klicke auf den Schmetterling um fortzufahren");
      } else {
        setText("蝶々をクリックしてください");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  const foundIt = () => {
    setBotCheck("butterfly");
    setImageClass("botCheck__clicked");
  };
  return (
    <div className="botCheck">
      <p>{text}</p>
      <div className="botCheck__images">
        <img
          src={leaf}
          alt="butterfly"
          onClick={foundIt}
          className={imageClass}
        />
        <img src={butterfly} alt="leaf" />
        <img src={leafs} alt="caterpillar" />
      </div>
    </div>
  );
}

export default BotCheck;
