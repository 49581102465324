import "./FAQ.css";
import React, { useState, useLayoutEffect } from "react";
import "./Contact.css";
import faqImg from "../images/faqImg.jpeg";
import faqImg1440 from "../images/faqImg1440.jpeg";
import faqImg1000 from "../images/faqImg1000.jpeg";
import faqImg600 from "../images/faqImg600.jpeg";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import Questions from "../faqComponents/Questions";

//firebase imports
import { db } from "../redux/firebase";
import { collection, getDocs } from "firebase/firestore";

function FAQ() {
  const language = useSelector(selectLanguage);
  const [questions, setQuestions] = useState([]);

  //translations
  const [title, setTitle] = useState();

  useLayoutEffect(() => {
    const getData = async () => {
      if (language === "eng") {
        setTitle("Questions");

        const querySnapShot = await getDocs(collection(db, "faqEng"));
        setQuestions(
          querySnapShot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      } else if (language === "deu") {
        setTitle(" Häufig gestellte Fragen");

        const querySnapShot = await getDocs(collection(db, "faqDeu"));
        setQuestions(
          querySnapShot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      } else {
        setTitle("よくある質問");

        const querySnapShot = await getDocs(collection(db, "faqJpn"));
        setQuestions(
          querySnapShot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      }
    };
    getData();
  }, [language]);
  return (
    <div className="faq">
      <div className="faq__top">
        <picture>
          <source
            media="(min-width:1440px)"
            srcSet={faqImg}
            className="faq__img"
          />
          <source
            media="(min-width:1000px)"
            srcSet={faqImg1440}
            className="faq__img"
          />
          <source
            media="(min-width:600px)"
            srcSet={faqImg1000}
            className="faq__img"
          />
          <img
            src={faqImg600}
            alt="about itadakihealthy's nutritionist"
            className="faq__img"
          />
        </picture>
        <div className="faq__title">
          <h1>{title}</h1>
        </div>
      </div>
      <Questions questions={questions} />
    </div>
  );
}

export default FAQ;
