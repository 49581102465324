import React, { useState, useEffect } from "react";
import "./Confirmation.css";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import { useHistory } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
function Confirmation() {
  const language = useSelector(selectLanguage);
  const history = useHistory();

  //translations
  const [title, setTitle] = useState();
  const [text, setText] = useState();
  const [back, setBack] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setTitle("Message Sent");
        setText("Your vegan nutritionalist will be in touch shortly.");
        setBack("Close");
      } else if (language === "deu") {
        setTitle("Nachricht gesendet");
        setText(
          "Deine vegane Ernähungsberaterin wird sich in Kürze bei dir melden"
        );
        setBack("schließen");
      } else {
        setTitle("メッセージありがとうございます。");
        setText("あなたのビーガン栄養士がまもなく連絡します。");
        setBack("閉める");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  return (
    <div className="confirmation">
      <ScrollToTop />
      <h2>{title}</h2>
      <p>{text}</p>
      <button onClick={() => history.push("/")}>{back}</button>
    </div>
  );
}

export default Confirmation;
