import "./ContactForm.css";
import SignUpButton from "../homeComponents/SignUpButton";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import Confirmation from "../components/Confirmation";
import emailjs from "emailjs-com";
import BotCheck from "./BotCheck";
import { Checkbox } from "@material-ui/core";

function ContactForm() {
  const language = useSelector(selectLanguage);

  const [title, setTitle] = useState();
  const [text, setText] = useState();
  const [name, setName] = useState("");
  const [nameTag, setNameTag] = useState();
  const [email, setEmail] = useState("");
  const [emailTag, setEmailTag] = useState();
  const [message, setMessage] = useState("");
  const [messageTag, setMessageTag] = useState();
  const [disclaimer, setDisclaimer] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [showWarning, setShowWarning] = useState();
  const [warning, setWarning] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [botCheck, setBotCheck] = useState("");

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setTitle("You're almost there!");
        setText(
          "Just fill out the contact form and your vegan nutritionist will be in touch."
        );
        setNameTag("Name");
        setEmailTag("Email");
        setMessageTag("What do you hope to get out of itadakihealthy?");
        setDisclaimer(
          "I understand that itadakihealthy does not substitute for medical advice, and that all responsibility to seek medical attention lies entirely with myself. I also testify that I do not have a serious medical condition."
        );
        setWarning(
          "Please fill out all fields in order to submit the contact form."
        );
      } else if (language === "deu") {
        setTitle(" Fast geschafft!");
        setText(
          "Trage dich in das Kontaktformular ein und ich werde dich in Kürze kontaktieren."
        );
        setNameTag("Name");
        setEmailTag("Email");
        setMessageTag("Wie kann dir ein itadakihealthy coaching helfen?");
        setDisclaimer(
          "Ich verstehe, dass itadakihealthys Ernährungsberatung nicht der Heilung oder Linderung von Krankheiten oder deren Beschwerden dient und keine individuelle Beratung im Krankheitsfall beinhaltet. Bei krankheitsbezogenen Beschwerden wende dich bitte an deinen Arzt, Diätassistenten oder Heilpraktiker."
        );
      } else {
        setTitle("もう少しだけ！");
        setText(
          "お問い合わせフォームを記入するだけで、あなたのビーガン栄養士から連絡が届きます。"
        );
        setNameTag("名前");
        setEmailTag("メールアドレス");
        setMessageTag("itadakihealthyを通じて何を希望していますか?");
        setDisclaimer(
          "itadakihealthyは医療アドバイスに代わるものではないことを理解しており、医師の診察を受ける責任はすべて私自身にあります。私は深刻な病状がないことを証言します。"
        );
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  //EmailJS
  const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_USER_ID } =
    process.env;

  const SERVICE_ID = REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = REACT_APP_TEMPLATE_ID;
  const USER_ID = REACT_APP_USER_ID;

  const templateParams = {
    from_name: name,
    message: message,
    from_email: email,
  };

  const sendEmail = () => {
    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID).then(
      () => {
        setEmailSent(true);
        setShowWarning(false);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const signUp = (e) => {
    if (
      name !== "" &&
      email !== "" &&
      message !== "" &&
      isChecked === true &&
      botCheck === "butterfly"
    ) {
      sendEmail();
    } else {
      setShowWarning(true);
    }
  };
  return (
    <div className="contactForm">
      <div className="contactFormContainer">
        <h2>{title}</h2>
        <p className="contactForm__text">{text}</p>
        {showWarning === true && (
          <p className="contactForm__warning">{warning}</p>
        )}
        <p>{nameTag}</p>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="contactForm__input lightGreenBackground"
        />
        <p>{emailTag}</p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="contactForm__input greenBackground"
        />
        <p className="">{messageTag}</p>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="contactForm__input darkGreenBackground"
        />
        <BotCheck setBotCheck={setBotCheck} />
        <div className="contactForm__disclaimer">
          {/* <input type="checkbox" onChange={()=>setIsChecked(!isChecked)} className="contactForm__check"/> */}
          <Checkbox
            value="checkedA"
            inputProps={{ "aria-label": "Checkbox A" }}
            onChange={() => setIsChecked(!isChecked)}
          />
          <p className="contactForm__text addPadding">{disclaimer}</p>
        </div>
        <div onClick={signUp}>
          <SignUpButton buttonTextClass="signUpButton__noText" />
        </div>
      </div>
      {emailSent === true && (
        <div className="contactForm__confirmation">
          <Confirmation />
        </div>
      )}
    </div>
  );
}

export default ContactForm;
