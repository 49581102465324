import React from "react";

function Juicer() {
  return (
    <div className="juicerContainer">
      <iframe
        title="instaFeed"
        className="juicer"
        src="https://www.juicer.io/api/feeds/itadakihealthy/iframe?truncate=50"
        frameborder="0"
        width="1000"
        height="1000"
      ></iframe>
    </div>
  );
}

export default Juicer;
