import React from "react";
import "./Menu.css";

function Menu({
  home,
  about,
  concept,
  contact,
  history,
  menuClass,
  menuId,
  handleShowMenu,
  faq,
}) {
  return (
    <div className="menuContainer">
      <div className={menuClass} id={menuId} onClick={handleShowMenu}>
        <h3 onClick={() => history.push("/")}>{home}</h3>
        <h3 onClick={() => history.push("/about")}>{about}</h3>
        <h3 onClick={() => history.push("/concept")}>{concept}</h3>
        <h3 onClick={() => history.push("/contact")}>{contact}</h3>
        <h3 onClick={() => history.push("/faq")}>{faq}</h3>
      </div>
    </div>
  );
}

export default Menu;
