import React from "react";
import stamp1 from "../images/nutritionStamp.png";
import stamp2 from "../images/motherChild.png";
import stamp3 from "../images/athletes.png";
import "./Stamps.css";

function Stamps({ stampsClass }) {
  return (
    <div className={stampsClass}>
      <a
        href="https://ecodemy.de/vegane-ernaehrungsberatung-verzeichnis/mitglieder/leonore-steffan/"
        target="_blank"
        rel="noopener noreferrer"
        title="Vegan nutritional counseling in Mitaka - Leonore Steffan"
      >
        <img src={stamp1} alt="vegan nutritionist certificate" />
      </a>
      <a
        href="https://ecodemy.de/vegane-ernaehrungsberatung-verzeichnis/mitglieder/leonore-steffan/"
        target="_blank"
        rel="noopener noreferrer"
        title="Vegan nutritional counseling in Mitaka - Leonore Steffan"
      >
        <img
          src={stamp2}
          alt="vegan nutrition for mother and child certificate"
        />
      </a>
      <a
        href="https://ecodemy.de/vegane-ernaehrungsberatung-verzeichnis/mitglieder/leonore-steffan/"
        target="_blank"
        rel="noopener noreferrer"
        title="Vegan nutritional counseling in Mitaka - Leonore Steffan"
      >
        <img
          src={stamp3}
          width="125"
          height="100"
          alt="Vegan nutritional counseling in Mitaka - Leonore Steffan"
        />
      </a>
    </div>
  );
}

export default Stamps;
