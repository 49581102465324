import React, { useState, useEffect } from "react";
import "./Footer.css";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

function Footer() {
  const language = useSelector(selectLanguage);

  const [text, setText] = useState();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setText(
          <div className="footer">
            <p>©2022 itadakihealthy</p>
            <div>
              Icons made by{" "}
              <a
                href="https://www.flaticon.com/authors/smashicons"
                title="Smashicons"
              >
                Smashicons
              </a>
              ,&nbsp;
              <a href="https://www.freepik.com" title="Freepik">
                Freepik
              </a>
              ,&nbsp;
              <a href="https://www.flaticon.com/authors/becris" title="Becris">
                Becris
              </a>
              ,&nbsp;
              <a
                href="https://www.flaticon.com/authors/pixel-perfect"
                title="Pixel perfect"
              >
                Pixel perfect
              </a>
              ,&nbsp;
              <a href="https://www.flaticon.com/authors/ddara" title="dDara">
                dDara
              </a>
              ,&nbsp;
              <a
                href="https://www.flaticon.com/authors/photo3idea-studio"
                title="photo3idea_studio"
              >
                photo3idea_studio
              </a>{" "}
              and&nbsp;
              <a
                href="https://www.flaticon.com/authors/linector"
                title="Linector"
              >
                Linector
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
              .
            </div>
            <div className="footer__privacyAndTerms">
              <a href="https://www.freeprivacypolicy.com/live/4c147510-e162-41de-98e1-002dd22c010d">
                Privacy{" "}
              </a>
            </div>
          </div>
        );
      } else if (language === "deu") {
        setText(
          <div className="footer">
            <p>©2021 itadakihealthy</p>
            <div>
              Icons entnommen von{" "}
              <a
                href="https://www.flaticon.com/authors/smashicons"
                title="Smashicons"
              >
                Smashicons
              </a>
              ,&nbsp;
              <a href="https://www.freepik.com" title="Freepik">
                Freepik
              </a>
              ,&nbsp;
              <a href="https://www.flaticon.com/authors/becris" title="Becris">
                Becris
              </a>
              ,&nbsp;
              <a
                href="https://www.flaticon.com/authors/pixel-perfect"
                title="Pixel perfect"
              >
                Pixel perfect
              </a>
              ,&nbsp;
              <a href="https://www.flaticon.com/authors/ddara" title="dDara">
                dDara
              </a>
              ,&nbsp;
              <a
                href="https://www.flaticon.com/authors/photo3idea-studio"
                title="photo3idea_studio"
              >
                photo3idea_studio
              </a>{" "}
              sowie&nbsp;
              <a
                href="https://www.flaticon.com/authors/linector"
                title="Linector"
              >
                Linector
              </a>{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
              .
            </div>
            <div className="footer__privacyAndTerms">
              <a href="https://www.freeprivacypolicy.com/live/4c147510-e162-41de-98e1-002dd22c010d">
                Datenschutz
              </a>
            </div>
          </div>
        );
      } else {
        setText(
          <div className="footer">
            <p>©2021 itadakihealthy</p>
            <div>
              このウエブサイトは{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
              の
              <a
                href="https://www.flaticon.com/authors/smashicons"
                title="Smashicons"
              >
                Smashicons
              </a>
              、&nbsp;
              <a href="https://www.freepik.com" title="Freepik">
                Freepik
              </a>
              ,&nbsp;
              <a href="https://www.flaticon.com/authors/becris" title="Becris">
                Becris
              </a>
              、&nbsp;
              <a
                href="https://www.flaticon.com/authors/pixel-perfect"
                title="Pixel perfect"
              >
                Pixel perfect
              </a>
              、&nbsp;
              <a href="https://www.flaticon.com/authors/ddara" title="dDara">
                dDara
              </a>
              、&nbsp;
              <a
                href="https://www.flaticon.com/authors/photo3idea-studio"
                title="photo3idea_studio"
              >
                photo3idea_studio
              </a>{" "}
              と&nbsp;
              <a
                href="https://www.flaticon.com/authors/linector"
                title="Linector"
              >
                Linector
              </a>{" "}
              のアイコンを使用しています。
            </div>
            <div className="footer__privacyAndTerms">
              <a href="https://www.freeprivacypolicy.com/live/4c147510-e162-41de-98e1-002dd22c010d">
                プライバシー
              </a>
            </div>
          </div>
        );
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);
  return (
    <div className="footer">
      <div className="footer__icons">
        <a href="https://www.facebook.com/search/top?q=itadakihealthy">
          <FacebookIcon className="footer__icon" />
        </a>
        <a href="https://www.instagram.com/itadakihealthy/">
          <InstagramIcon className="footer__icon" />
        </a>
      </div>
      {text}
    </div>
  );
}

export default Footer;
