import React from "react";
import Question from "./Question";
import "./Questions.css";

function Questions({ questions }) {
  return (
    <ol className="questions">
      {questions.length !== 0 &&
        questions.map(({ id, data: { question, answer } }) => (
          <Question
            key={id}
            question={question}
            answer={answer}
            i={questions.indexOf(id)}
          />
        ))}
    </ol>
  );
}

export default Questions;
