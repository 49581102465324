import React from 'react';
import "./Collage.css"
import holdingApple from "../images/holding-apple-cut1.jpeg";
import holdingTea from "../images/holdingTea.jpeg";
import grapesBox from "../images/grapesBox.jpeg";
import salad from "../images/salad.jpeg";
import eatingWatermelon from "../images/eatingWatermelon.jpeg";
import foodImg from "../images/foodImg.jpeg";
function Collage() {
    return (
        <div className="collage">
            <div className="collage__container">
                <div className="collage__left">
                    <img src={holdingApple} alt="" className="collage__bigSquare"/>
                    <div className="collage__leftBottom">
                        <img src={grapesBox} alt="grapes in box" className="collage__smallRectangle"/>
                        <img src={holdingTea} alt="women holding tea" className="collage__smallRectangle"/>
                    </div>
                </div>
                <div className="collage__right">
                    <div className="collage__rightTop">
                    <img src={salad} alt="salad"  className="collage__smallSquare"/>
                    <img src={eatingWatermelon} alt="women eating watermelon"  className="collage__smallSquare"/>
                    </div>
                    <img src={foodImg} alt="cut fruit and tea" className="collage__bigRectangle"/>
                </div>
            </div>
        </div>
    )
}

export default Collage
