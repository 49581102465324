import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/languageSlice";
import "./PromoBar.css";

const PromoBar = () => {
  const language = useSelector(selectLanguage);

  //translations
  const [text, setText] = useState("");

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (language === "eng") {
        setText("To the itadakihealthy shop");
      } else if (language === "deu") {
        setText("To the itadakihealthy shop");
      } else {
        setText("オのンラインストアへ");
      }
    }
    return function cleanup() {
      mounted = false;
    };
  }, [language]);

  return (
    <div className="promoBar">
      <a
        href="https://payhip.com/itadakihealthy"
        target="blank"
        rel="noopener noreferrer"
      >
        <p>{text}</p>
      </a>
    </div>
  );
};

export default PromoBar;
